<template>
  <div class="canvas-view" style="height: 100%; width: 100%">
    <canvas id="canvasRender"></canvas>
    <div class="compass">
      <img class="icon" src="/images/compass.png" :style="{ transform: 'rotate(' + rotateValue + 'deg)' }" />
    </div>
    <div class="bottom-card">
      <div class="top">
        <div class="title">当前日期：</div>
        <div class="date" @click="showCalendar = true">
          <span class="text">{{ dateStr }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="time-wrapper">
        <div class="time-slider">
          <span class="time">00:00</span>
          <van-slider v-model="time" @change="onTimeChange" :min="0" :max="24" :step="0.0001" button-size="14px" />
          <span class="time">24:00</span>
          <span class="current-time">{{ timeStr }}</span>
        </div>
        <van-icon class="play" @click="play" :name="playing ? 'pause-circle-o' : 'play-circle-o'" size="24px" />
      </div>
      <div class="times">
        <p>
          日出时间<span class="value">{{ riseTimeStr }}</span>
        </p>
        <p>
          日落时间<span class="value">{{ setTimeStr }}</span>
        </p>
      </div>
      <div class="buildNumber-terms" id = 'nav'>
        <van-button class="btn" :class="{ active: selectBuildNumber === item }" plain size="mini" type="default"
          v-for="(item,index) in buildNumbers" :key="index" :id="'button-' + index" @click="choseBuildNumber(item)">{{ item + ' #'}}</van-button>
      </div>
      <div class="solar-terms">
        <van-button class="btn" :class="{ active: dateStr === item.date }" plain size="mini" type="default"
          v-for="item in dates" :key="item.date" @click="choseDate(item.date)">{{ item.name }}</van-button>
      </div>
    </div>
    <van-calendar v-model:show="showCalendar" :min-date="minDate" :max-date="maxDate" @confirm="onChoseDate" />
  </div>
</template>

<script>
import HouseLightOpen from "./houseLightOpen";
import { getCommunityDetail } from '@/utils/api'
import utils from '@/utils/index'

// import axios from "axios";

let sunView = null;
let currentDate = new Date();
export default {
  name: "HouseLightResidential",
  props: {
    msg: String,
  },

  data() {
    return {
      isVip: false,
      idleTimer: null,

      date: new Date(),
      time: 9, // 0-24
      riseTimeStr: '',
      setTimeStr: '',
      noonTimeStr: '',
      dayLengthStr: '',
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2024, 11, 31),
      showCalendar: false,
      playing: false,
      loaded: false,
      rotateValue: 0,
      selectBuildNumber:'',
      buildNumbers:[],
      dates: [
        {
          name: '立春',
          date: currentDate.getFullYear() + '-02-04'
        },
        {
          name: '春分',
          date: currentDate.getFullYear() + '-03-20'
        },
        {
          name: '立夏',
          date: currentDate.getFullYear() + '-05-05'
        },
        {
          name: '夏至',
          date: currentDate.getFullYear() + '-06-21'
        },
        {
          name: '立秋',
          date: currentDate.getFullYear() + '-08-07'
        },
        {
          name: '秋分',
          date: currentDate.getFullYear() + '-09-22'
        },
        {
          name: '立冬',
          date: currentDate.getFullYear() + '-11-07'
        },
        {
          name: '冬至',
          date: currentDate.getFullYear() + '-12-21'
        },
      ]
    }
  },

  computed: {
    dateStr() {
      return utils.dateFormat(this.date, 'yyyy-MM-dd')
    },
    timeStr() {
      return utils.timeFormat(this.time)
    }
  },

  mounted() {
    document.title = "采光分析大师";
    this.throttledHandleRotateChange = utils.throttle(this.handleRotateChange, 200)
    let code = this.$route.params.code;
    let date = new Date();

    getCommunityDetail(code).then((res) => {
      let result = res.data.result;
      this.buildNumbers.push(result.destBuildingNumber)
      result.barrierReqList.forEach(element => {
        this.buildNumbers.push(element.buildingNumber)
      });
      sunView = new HouseLightOpen({
        element: document.querySelector("#canvasRender"),
        historyId: code,
        date: date,
        history: result,
        onRotate: this.throttledHandleRotateChange,
        onCancelBuildSelect: this.cancelBuildNumber,
        onChoseBuildNumber: this.onChoseBuildNumber
      });
      sunView.init();
      this.onChoseDate(date);
    })
  },

  unmounted() {
    sunView.destroy()
  },

  beforeUnmount() {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }
  },

  methods: {
    onChoseDate(val) {
      this.showCalendar = false
      val.setHours(12);
      let sun = sunView.dateUpdate(val);
      this.date = val;
      this.riseTimeStr = this.timeFormat(sun[0].getHours()) + ":" + this.timeFormat(sun[0].getMinutes());
      this.setTimeStr = this.timeFormat(sun[1].getHours()) + ":" + this.timeFormat(sun[1].getMinutes());
      this.time = this.timeToValue(sun[0]);
    },

    choseDate(dateStr) {
      let date = new Date(dateStr);
      this.date = date;
      this.onChoseDate(date);
    },

    choseBuildNumber(number){
      sunView.switchHighlightBuilding(number)
    },

    onChoseBuildNumber(number){
      this.selectBuildNumber = number
    },

    cancelBuildNumber(){
      this.selectBuildNumber = ""
    },

    onTimeChange(val) {
      console.log(val);
      sunView.time = val
    },

    play() {
      this.playing = !this.playing
      clearTimeout(this.timer)
      this.timer = 0
      if (this.playing) {
        this.timer = setInterval(() => {
          let interval = 0.05
          if (this.time < 4 || this.time > 21) {
            interval = 0.5 // 晚上加速
          }
          this.time = this.time + interval
          if (this.time > 24) {
            this.time = 0
          }
          sunView.time = this.time
        }, 50)
      }
    },

    timeToValue(time) {
      let value = 0;
      var hour = time.getHours();
      var minute = time.getMinutes();
      value = hour + minute / 60;
      return value;
    },

    timeFormat(time) {
      if (time < 10)
        return '0' + time
      else
        return time
    },

    handleRotateChange(value) {
      this.rotateValue = value * 180 / Math.PI
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.label{
  color: #fff;
  background: #FF7522;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 14px;
}

.label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px; /* 小尖的宽度的一半，使其居中 */
  border-width: 5px; /* 小尖的大小 */
  border-style: solid;
  border-color: #FF7522 transparent transparent transparent; /* 上边框颜色为气泡框背景色 */
}

.label-container{
  display: flex;
  height: 25px;
}

.label-btn{
  // position: relative;
  height: 100%;
  background: #fff;
  margin-left: 5px;
  border-radius: 5px;
}

.label-btn img{
  position: relative;
  height: 100%;
  object-fit: contain;
}

.compass {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 0;
  border-radius: 50%;
  overflow: hidden;
}

.icon {
  width: 80px;
  height: 80px;
}

.canvas-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .bottom-card {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    padding: 0 10px 4px;
    z-index: 1000;
    .top {
      display: flex;
      justify-content: center;
      /* justify-content: space-between; */
      line-height: 45px;

      .title {
        // flex: 1;
        // font-size: 18px;
        // font-weight: 500;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }

      .date {

        // display: flex;
        // justify-content: flex-end;
        // align-items: center;
        // width: 100px;
        // font-size: 14px;
        .text {
          margin-right: 4px;
        }
      }
    }

    .time-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      line-height: 20px;

      .time-slider {
        flex: 1;
        display: flex;
        align-items: center;

        .time {
          font-size: 14px;
          margin: 0 10px;
        }

        .current-time {
          padding: 1px 4px 0;
          font-size: 14px;
          background: rgba(0, 0, 0, 0.08);
        }
      }

      .play {
        margin: 0 20px;
      }
    }

    .times {
      display: flex;
      flex-flow: row nowrap;
      /* justify-content: space-around; */
      justify-content: space-between;
      font-size: 14px;
      margin: 0 10px;

      .value {
        margin-left: 4px;
        color: rgb(255, 58, 58);
      }
    }

    .buildNumber-terms{
      // display: flex;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      .btn {
        width: auto;
        min-width: 100px;
        white-space: nowrap; /* 防止文本换行 */
        font-size: 14px;
        padding: 5px;
        margin: 5px;
      }

      .active {
        border: none;
        color: #fff;
        background: linear-gradient(90deg,
            rgba(250, 170, 70, 1) 0%,
            rgba(250, 100, 17, 1) 100%);
      }
    }

    .solar-terms {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .btn {
        font-size: 14px;
        flex: 1 1 20%;
        margin: 5px;
      }

      .active {
        border: none;
        color: #fff;
        background: linear-gradient(90deg,
            rgba(250, 170, 70, 1) 0%,
            rgba(250, 100, 17, 1) 100%);
      }
    }
  }

  .compass {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 0;
    border-radius: 50%;
    overflow: hidden;

    .icon {
      width: 80px;
      height: 80px;
    }
  }
}

.show-pay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showPay-btn {
  width: 100vw;
  height: 100vh;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.message-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.message-box-content {
  width: 80%;
  border-radius: 10px;
  background-color: rgb(215, 215, 215);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 0 5vw 1vh;
}

.footer {
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
}

.footer-btn {
  width: 50%;
  border: none;
  background-color: #fff;
}

.cancel {
  border-radius: 0 0 0 10px;
  background-color: #ddd;
  color: black;
}

.confirm {
  border-radius: 0 0 10px 0;
  background-color: #4CAF50;
  color: white;
}

</style>
