import { createRouter, createWebHashHistory } from 'vue-router'
import HouseLight from './components/HouseLight.vue'
import WindowShadow from './components/WindowShadow.vue'
import HouseLightResidential from './components/HouseLightResidential.vue'
 
const routes = [
  {
    path: '/residential/:code',
    name: 'Residential',
    component: HouseLightResidential
  },
  {
    path: '/house',
    name: 'HouseLight',
    component: HouseLight
  },
  {
    path: '/window',
    name: 'WindowShadow',
    component: WindowShadow
  }
]
 
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
 
export default router
